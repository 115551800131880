import React, {Component} from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import EllipsisLoader from '../../EllipsisLoader'
import BackgroundImage from 'gatsby-background-image'

const ProductCatalogueWrapper = styled.div`
  position: relative;
  padding: 0 0 100px 0;
  .intro {
    background: ${props => props.theme.grey3};
    .inner {
      margin: 0 auto;
      padding: 60px 20px;
      max-width: 750px;
      text-align: center;
    }
    h2 {
      margin: 0 0 40px 0 !important;
      p {
        line-height: 30px;
      }
    }
  }
`

const ProductBackground = styled.div`
  background-image: linear-gradient(#cad4e0 40%, white 40%);
  .products-slider-id,
  .products-slider-id::before,
  .products-slider-id::after {
    display: block !important;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 50% !important;
    height: 100px !important;
    transition: all 500ms ease-in-out;
    margin-bottom: 10px;
  }
  @media (max-width: 900px) {
    .products-slider-id,
    .products-slider-id::before,
    .products-slider-id::after {
      height: 80px !important;
      background-size: 100% !important;
    }
  }
`

const BedPrice = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-end;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin: 20px auto;
  max-width: 600px;
  span {
    margin: 0 12px 12px 0;
    font-weight: bold;
  }
  h1 {
    color: ${props => props.theme.grey1};
    font-size: 60px;
    font-weight: bold;
    text-align: center;
    margin: 25px 0 0 0;
    @media (max-width: 420px) {
      font-size: 50px;
    }
  }
`

const Tabs = styled.div`
  text-transform: uppercase;
  text-align: center;
  max-width: 680px;
  margin: 0 auto;
  a {
    color: ${props => props.theme.grey1};
    border-bottom: 3px solid ${props => props.theme.white};
    padding: 10px 20px;
    display: inline-block;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    outline: none;
    position: relative;
    &.is-current {
      font-weight: 600;
      font-size: 150%;
      &:after {
        content: '';
        background: ${props => props.theme.grey1};
        position: absolute;
        bottom: 0;
        left: calc(50% - 30px);
        height: 3px;
        width: 60px;
      }
    }
  }
  .tab {
    max-width: 30%;
    width: 30%;
    @media (max-width: 480px) {
      max-width: 100%;
      width: auto;
    }
  }
  @media (max-width: 640px) {
    margin: 20px;
  }
`
const TabContent = styled.div`
  max-width: 600px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-gap: 30px;
  align-items: center;
  img {
    //border: 4px solid ${props => props.theme.red};
    border-radius: 50%;
  }
  @media (max-width: 640px) {
    margin: 20px;
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    text-align: center;
    img {
      width: 60%;
    }
  }
`
const TabArrowBox = styled.div`
  width: 65%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -160px;
  @media (max-width: 768px) {
    width: 90%;
  }
  @media (max-width: 480px) {
    width: 90%;
    margin-top: -250px;
  }
`
const TabNext = styled.div`
  border-right: 5px solid ${props => props.theme.grey1};
  border-bottom: 5px solid ${props => props.theme.grey1};
  width: 25px;
  height: 25px;
  transform: rotate(-45deg);
  border-radius: 2px;
  position: relative;
  float: right;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  &:active {
    transform: scale(0.9) rotate(-45deg);
  }
`
const TabPrev = styled.div`
  border-left: 5px solid ${props => props.theme.grey1};
  border-bottom: 5px solid ${props => props.theme.grey1};
  width: 25px;
  height: 25px;
  transform: rotate(45deg);
  border-radius: 2px;
  position: relative;
  float: left;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  &:active {
    transform: scale(0.9) rotate(45deg);
  }
`
const BtnShopNow = styled(Link)`
  background: ${props => props.theme.red};
  color: ${props => props.theme.white};
  margin-top: 25px;
  position: absolute;
  z-index: 9;
  left: calc(50% - 125px);
  min-width: 250px;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  border-radius: 50px;
  transition: all 200ms ease-in-out;
  font-weight: bold;
  &:hover {
    color: ${props => props.theme.white};
    background: ${props => props.theme.redHover};
  }
`

const ThumbAnimation = styled.div`
  transition: all 300ms ease-in-out;
  opacity: ${props => (props.visible ? '1' : '0')};
  /* transform: translate(${props => (props.visible ? '0' : '-30px')}, 0); */
`

const TextAnimation = styled.div`
  transition: all 300ms ease-in-out;
  opacity: ${props => (props.visible ? '1' : '0')};
  /* transform: translate(${props => (props.visible ? '0' : '30px')}, 0); */
`

class ProductCatalogueSlider extends Component {
  state = {
    inMotion: false,
    tabIndex: 1,
    imgPos: '50%',
  }

  componentDidMount() {
    this.setState({
      inMotion: true,
    })
  }

  changeTabHandler = tabIndex => {
    const imgPos = tabIndex * 5 * 10
    this.setState({
      inMotion: false,
    })
    // Change Tab Content
    setTimeout(() => {
      this.setState({
        inMotion: true,
        tabIndex: tabIndex,
        imgPos: `${imgPos}%`,
      })
    }, 300)
  }

  render() {
    const {data, productData, topSellerImages} = this.props
    const {inMotion, tabIndex} = this.state
    const currentTab = data.catalogue_products[tabIndex]

    let currentPrice = 0
    try {
      const currentProduct =
        currentTab.name &&
        productData.filter(
          prod =>
            prod.slug.includes(
              currentTab.name.toLowerCase().replace(' ', '-'),
            ) && prod.slug.includes('kooi'),
        )
      const currentProductBase =
        currentTab.name &&
        productData.filter(prod => prod.slug.includes('kooi-pure-base'))
      currentPrice =
        currentProduct && currentProduct.length > 0 && currentProduct[0].price
      if (
        currentProductBase &&
        currentProductBase.length > 0 &&
        currentProductBase[0].price
      ) {
        currentPrice =
          parseFloat(currentPrice) + parseFloat(currentProductBase[0].price)
      }
      currentPrice = parseFloat(currentPrice)
    } catch {}

    function arrowNext() {
      if (
        document
          .getElementsByClassName('tab')[0]
          .classList.contains('is-current')
      ) {
        document.getElementsByClassName('tab')[1].click()
      }
      if (
        document
          .getElementsByClassName('tab')[1]
          .classList.contains('is-current')
      ) {
        document.getElementsByClassName('tab')[2].click()
      }
    }

    function arrowPrev() {
      if (
        document
          .getElementsByClassName('tab')[2]
          .classList.contains('is-current')
      ) {
        document.getElementsByClassName('tab')[1].click()
      }
      if (
        document
          .getElementsByClassName('tab')[1]
          .classList.contains('is-current')
      ) {
        document.getElementsByClassName('tab')[0].click()
      }
    }

    return (
      <ProductCatalogueWrapper>
        <div className="intro">
          <div className="inner">
            <h2
              className="red"
              dangerouslySetInnerHTML={{__html: data.catalogue_title}}
            />
            <div dangerouslySetInnerHTML={{__html: data.catalogue_intro}} />
          </div>
        </div>
        <Link to={currentTab.slug}>
          <ProductBackground>
            <BackgroundImage
              loading="eager"
              className="products-slider-id"
              fluid={topSellerImages[this.state.tabIndex].childImageSharp.fluid}
            />
          </ProductBackground>
        </Link>
        <Tabs>
          {data.catalogue_products.map((prod, i) => {
            let isCurrent = ''
            if (prod.name === currentTab.name) {
              isCurrent = 'is-current'
            }
            return (
              <a
                key={prod.name}
                className={`tab ${i} ${isCurrent}`}
                onClick={() => this.changeTabHandler(i)}
                onKeyPress={() => this.changeTabHandler(i)}
                role="button"
                tabIndex={0}
              >
                {prod.name}
              </a>
            )
          })}
        </Tabs>
        <BedPrice>
          {currentPrice && (
            <>
              <span>FROM</span>
              <h1>R {currentPrice.toFixed(2)}</h1>
            </>
          )}
        </BedPrice>
        <TabContent>
          <ThumbAnimation visible={inMotion}>
            <Img
              fixed={currentTab.thumbnail.localFile.childImageSharp.fixed}
              alt=""
            />
          </ThumbAnimation>
          <TextAnimation visible={inMotion}>
            {currentTab.description}
          </TextAnimation>
        </TabContent>
        <TabArrowBox>
          <TabNext onClick={arrowNext} />
          <TabPrev onClick={arrowPrev} />
        </TabArrowBox>
        <BtnShopNow to={currentTab.slug}>
          Shop Kooi {currentTab.name}
        </BtnShopNow>
      </ProductCatalogueWrapper>
    )
  }
}

export default ProductCatalogueSlider
