import React, {useState, useEffect} from 'react'
import loadable from '@loadable/component'
import {graphql, useStaticQuery} from 'gatsby'
import Layout from '../components/Layout'
import useMobileHook from '../utils/use-mobile-hook'
import SEO from '../components/SEO'

// All visible components in display order
import Slider from '../components/Homepage/Slider'
import ProductCatalogueSlider from '../components/Homepage/ProductCatalogueSlider'
import IconsRow from '../components/Icons/IconsRow'
import YoutubeEmbed from '../components/shared/YoutubeEmbed'

// These below the fold components are unbundled
const StatsCounter = loadable(() => import('../components/shared/StatsCounter'))
const ProductTopSellers = loadable(() =>
  import('../components/Homepage/ProductTopSellers'),
)
// const SleepTrackerBanner = loadable(() =>
//   import('../components/shared/SleepTrackerBanner'),
// )
const FeaturedInLogoWall = loadable(() =>
  import('../components/shared/FeaturedInLogoWall'),
)
const CTA = loadable(() => import('../components/shared/CTA'))
const MainProducts = loadable(() => import('../components/MainProducts'))

const HomePage = ({location}) => {
  const data = useStaticQuery(graphql`
    {
      allWcProducts(
        filter: {status: {eq: "publish"}, catalog_visibility: {eq: "visible"}}
      ) {
        nodes {
          price
          slug
        }
      }
      wp: wordpressPage(wordpress_id: {eq: 121}) {
        title
        content
        acf {
          slider {
            slider_title
            slider_image {
              localFile {
                childImageSharp {
                  fluid(
                    quality: 90
                    maxWidth: 1600
                    srcSetBreakpoints: [400, 900]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            slider_image_mobile {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 400, srcSetBreakpoints: [400]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            button_text
            button_link
          }
          catalogue_title
          catalogue_intro
          catalogue_products {
            name
            description
            slug
            thumbnail {
              source_url
              localFile {
                childImageSharp {
                  fixed(width: 120, height: 120, quality: 80) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
          cta_title
          cta_description
          cta_button_text
          cta_link
        }
      }
      seo: wpgraphql {
        pageBy(pageId: 121) {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage
            twitterTitle
            twitterDescription
            twitterImage
          }
        }
      }
      productFirmImage: file(
        relativePath: {eq: "kooi-body-charger-inner-full.png"}
      ) {
        childImageSharp {
          fluid(maxHeight: 400, quality: 80, srcSetBreakpoints: [200]) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      productMediumImage: file(
        relativePath: {eq: "kooi-nero-boost-inner-full.png"}
      ) {
        childImageSharp {
          fluid(maxHeight: 400, quality: 80, srcSetBreakpoints: [200]) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      productPlushImage: file(
        relativePath: {eq: "kooi-body-trainer-inner-full.png"}
      ) {
        childImageSharp {
          fluid(maxHeight: 400, quality: 80, srcSetBreakpoints: [200]) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  // Convenience variables
  let productImages = []
  productImages[0] = data.productFirmImage
  productImages[1] = data.productMediumImage
  productImages[2] = data.productPlushImage

  return (
    <Layout location={location} flush>
      <SEO title="Kooi Beds" location={location} seo={data.seo.pageBy.seo} />
      <Slider slides={data.wp.acf.slider} />
      <ProductCatalogueSlider
        data={data.wp.acf}
        productData={data.allWcProducts.nodes}
        topSellerImages={productImages}
      />
      <IconsRow />
      {/*<StatsCounter />*/}
      <YoutubeEmbed />
      <ProductTopSellers />
      {/*<SleepTrackerBanner />*/}
      <FeaturedInLogoWall />
      <CTA data={data.wp.acf} />
      <MainProducts title="Featured" />
    </Layout>
  )
}

export default HomePage
