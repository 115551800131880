import React from 'react'
// import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const YoutubeEmbed = () => {
  // const [testIframe, setTestIframe] = useState('')
  //
  // const isBrowser = typeof window !== "undefined";
  // console.log(isBrowser);
  //
  // useEffect(() => {
  //     if (isBrowser) {
  //         const params = new URLSearchParams(window.location.search);
  //         setTestIframe(params.get('testIframe'))
  //         console.log(params);
  //         console.log(testIframe);
  //     }
  // }, [isBrowser]);

  // if (testIframe === 'yes') {
  return (
    <YoutubeEmbedWrapper>
      <iframe
        className={'responsive-iframe'}
        src="https://www.youtube.com/embed/3Hi6I9oK0N4?autoplay=1&mute=1&loop=1&modestbranding=1&rel=0"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </YoutubeEmbedWrapper>
  )
  // } else {
  //     return (<></>)
  // }
}

export default YoutubeEmbed

const YoutubeEmbedWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`
